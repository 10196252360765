module services {
    export module applicationcore {
        export class userAccountService implements interfaces.applicationcore.IUserAccountService {
            static $inject = ["$resource", "ENV", "$q", "$timeout", "$rootScope"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig,
                private $q: ng.IQService, private $timeout: ng.ITimeoutService,
                private $rootScope: interfaces.applicationcore.IRootScope) {
            }

            loginUser(): ng.resource.IResourceClass<interfaces.applicationcore.IUser> {
                return <ng.resource.IResourceClass<interfaces.applicationcore.IUser>>
                    this.$resource(this.ENV.DSP_REQUEST_URL_USER + "Authenticate", {});
            }

            loginADUser(): ng.resource.IResourceClass<interfaces.applicationcore.IUser> {
                return <ng.resource.IResourceClass<interfaces.applicationcore.IUser>>
                    this.$resource(this.ENV.DSP_REQUEST_URL_USER + "AuthenticateAD", {});
            }

            loginAADUser(): ng.resource.IResourceClass<interfaces.applicationcore.IUser> {
                return <ng.resource.IResourceClass<interfaces.applicationcore.IUser>>
                    this.$resource(this.ENV.DSP_REQUEST_URL_USER + "AuthenticateAAD", {});
            }

            loadUserProfile(): ng.resource.IResourceClass<ng.resource.IResource<interfaces.applicationcore.IUser>> {

                return <ng.resource.IResourceClass<ng.resource.IResource<interfaces.applicationcore.IUser>>>
                    this.$resource(this.ENV.DSP_REQUEST_URL_USER + "Get", {}, {
                        get: {
                            method: 'GET',
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            logoutUser(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_REQUEST_URL_USER + "Unauthenticate");
            }

            hasRight(rightId: number): ng.resource.IResourceClass<ng.resource.IResource<boolean>> {
                return this.$resource<ng.resource.IResource<boolean>>(this.ENV.DSP_REQUEST_URL_USER + "HasRight", {
                    rightId: rightId
                });
            }

            hasAccess(state: ng.ui.core.ITargetState): ng.IPromise<boolean> {
                var defer = this.$q.defer<boolean>();

                var url = "#!";
                url += state.$state().path[2].url.pattern;

                if (url.indexOf("?") > -1)
                    url = url.substr(0, url.indexOf("?"));
                
                (<ng.resource.IResourceClass<ng.resource.IResource<boolean | string>>>
                    this.$resource(this.ENV.DSP_REQUEST_URL_USER + "HasAccess", {
                    url: url
                    })).get((result: interfaces.applicationcore.IHasAccessReponse) => {
                    if (result.HasAccess) {
                            defer.resolve(result.HasAccess);
                        } else {
                            defer.reject("#!" + state.$state().path[2].url.pattern);
                        }
                    }, (errorResult) => {
                        defer.reject(errorResult);
                    });

                return defer.promise;
            }

            changePassword(password: string, confirmPassword: string, resetToken?: string): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_REQUEST_URL_USER + (resetToken ? "ForgotPassword" : "ChangePassword"), {
                    password: password,
                    confirmPassword: confirmPassword,
                    resetToken: resetToken
                });
            }

            removeEventUserSubscription(eventUserSubscriptionId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_REQUEST_URL_USER +  "RemoveEventUserSubscription", {
                    eventUserSubscriptionId: eventUserSubscriptionId
                });
            }

            resetPassword(): ng.resource.IResourceClass<ng.resource.IResource<boolean>> {

                return this.$resource<ng.resource.IResource<boolean>>(this.ENV.DSP_REQUEST_URL_USER + "RequestPasswordReset", {
                    });
            }

            saveUserProfile(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_REQUEST_URL_USER + "Save");
            }

            getDropdownList(searchText: string, entityId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_REQUEST_URL_USER + "GetDropdownList", {
                    searchText: searchText,
                    entityId: entityId
                });
            }

            getUserDesignationByEntity(entityId: number): ng.resource.IResourceClass<interfaces.applicationcore.IUserEntityDesignation> {

                return this.$resource<interfaces.applicationcore.IUserEntityDesignation>(this.ENV.DSP_REQUEST_URL_USER + "GetUserDesignationByEntity",
                    {
                        entityId: entityId
                    }, {
                        get: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    }
                );
            }
        }
    }
    angular.module("app").service("userAccountService", services.applicationcore.userAccountService);
}